import styles from './DeliveryPopup.module.scss';
import delivery from '../../assets/deliveryPopup.png';
import close from '../../assets/deliveryClose.png';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';

function DeliveryPopup({ closer }: any) {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <div className={`${styles.background} ${isMobile ? styles.isMobile : ''}`}>
      <div className={styles.modalWrap}>
        <header>
          <img src={close} onClick={() => closer(false)} />
        </header>
        <div className={styles.contentWrap}>
          <img src={delivery} />
        </div>
      </div>
    </div>
  );
}

export default DeliveryPopup;
